
<style>
.serviceRemark{
    min-width: 200px;
    /* height: 50px; */
    padding: 5px;
    border: 1px solid #5cadff;
    border-radius: 3px;
    color: #808695;
    font-weight: 400;

    position: fixed;
    top: -20px;
    background-color: papayawhip;
    z-index: 999;
}
</style>
<template>
  <div>
    <Row class="m-b-10">
       <i-col span="24">
        <h2>
          {{detailQuery.currentUserType===1?(feeInfo.demandCompanyName+' & '+feeInfo.supplierCompanyName):
          (detailQuery.currentUserType===2?feeInfo.supplierCompanyName:feeInfo.demandCompanyName)
          }}
          {{feeInfo.year?feeInfo.year+'年':'2020年'}}{{feeInfo.name}}对账簿</h2>
      </i-col>
      <i-col span="24" class="text-right">
        <slot name="header-owner">
          <Button
            type="primary"
            class="m-r-5"
            v-if="feeInfo.status===2"
            @click="handleLaunch"
          >确认发起对账</Button>
          <Button
            type="error"
            class="m-r-5"
            v-if="feeInfo.status===3"
            @click="handleRecall"
          >撤回发起对账</Button>
        </slot>
        <slot name="header-supplier">
          <Button type="primary" class="m-r-5" v-if="feeInfo.status===3" @click="handleLaunch">确认对账</Button>
          <Button type="error" class="m-r-5" v-if="feeInfo.status===3" @click="handleRecall">拒绝对账</Button>
        </slot>
         <slot name="header-supplier">
          <Button type="primary"  class="m-r-5"  @click="handleDownload">下载明细</Button>
        </slot>
      </i-col>
    </Row>
    <Tabs v-model="defaultTab" @on-click="handleSwitchTab">
      <TabPane
        v-for="(item,index) in feeTypeBillList"
        :key="'t_'+index"
        :label="item.feeTypeName"
        :name="item.mspTaskType.toString()"
      >
        <Table  stripe :data="list" :columns="dataColumns"></Table>
        <div class="paging_style">
          <Page size="small"
            :current="query.pageNumber"
            :page-size="query.pageSize"
            :total="total"
            @on-change="changePage"
            show-total
            show-elevator
          ></Page>
        </div>
      </TabPane>
    </Tabs>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
// import { getSupplierBill } from '@/api/statement/supplierbill'
// import { getAgentBillList } from '@/api/statement/agentsupplierbill'
import { getSupplierBill } from '@/api/statement/supplierbill'
import { updateBillStatus } from '@/api/statement/bill'

import { getPenaltyPage } from '@/api/statement/penalty'
import { listMaintainAmountDetail } from '@/api/dw/verify'
import { downloadFileRequest } from '@/utils/download'
export default {
  props: {
    detailQuery: {
      type: Object
    }
  },
  data () {
    return {
      query: {
        supplierCompanyId: this.detailQuery.supplierCompanyId,
        companyId: this.detailQuery.demandCompanyId,
        publisherId: this.detailQuery.publisherId,
        startDate: this.detailQuery.startDate,
        endDate: this.detailQuery.endDate,
        pageNumber: 1,
        pageSize: 15
      },
      defaultTab: '',
      selectedTitleValue: 1,
      list: [],
      total: 0,
      dataColumns: [],
      tableColumns: [
        {
          title: '资产名称',
          align: 'center',
          key: 'assetName'
        },
        {
          title: '站点',
          align: 'center',
          key: 'stationName'
        },
        {
          title: '资源类型',
          align: 'center',
          key: 'resourceTypeName'
        },
        {
          title: '编号',
          align: 'center',
          key: 'resourceCode'
        },
        {
          title: '面积（㎡）',
          align: 'center',
          key: 'area'
        },

        {
          title: '作业完成时间',
          align: 'center',
          key: 'chargeTime'
        },
        {
          renderHeader: (h, params) => {
            return h('div', [
              h('span', '质检时间'),
              h('Icon', {
                props: { type: 'md-help-circle', size: 18, color: '#2d8cf0', id: 'serviceIon' },
                style: { 'margin-left': '4px', cursor: 'pointer' },
                on: {
                  click: (event) => {
                    if (document.getElementsByClassName('serviceRemark').length < 1) {
                      var serviceDiv = document.createElement('div')
                      serviceDiv.className = 'serviceRemark'

                      // const title = document.createElement('p')
                      // title.innerHTML = '计费时间是指：'
                      // serviceDiv.appendChild(title)

                      const content = document.createElement('p')
                      content.innerHTML = '此时间为质检人员质检通过时间。'
                      serviceDiv.appendChild(content)

                      event.target.parentElement.appendChild(serviceDiv)
                      // 3秒后自动关闭
                      setTimeout(() => {
                        if (serviceDiv.parentNode) {
                          serviceDiv.parentNode.removeChild(serviceDiv)
                        }
                      }, 3000)
                    } else {
                      const serviceDivRemark = document.getElementsByClassName('serviceRemark')
                      serviceDivRemark[0].parentNode.removeChild(serviceDivRemark[0])
                    }
                  }
                }
              })
            ])
          },
          align: 'center',
          key: 'chargeTime'
        },
        {
          title: '应付金额',
          align: 'center',
          key: 'amount',
          render: (h, param) => {
            return h('span', toMoney(param.row.cost))
          }
        }
      ],

      fineQuery: {
        supplierCompanyId: this.detailQuery.supplierCompanyId,
        agentCompanyId: this.detailQuery.demandCompanyId,
        publisherId: this.detailQuery.publisherId,
        minPenaltyDate: this.detailQuery.startDate,
        maxPenaltyDate: this.detailQuery.endDate,
        status: 5,
        pageNumber: 1,
        pageSize: 15
      },
      tableColumns2: [
        {
          title: '罚款日期',
          align: 'center',
          key: 'penaltyDate'
        },
        {
          title: '创建时间',
          align: 'center',
          key: 'createTime'
        },
        {
          title: '罚款原因',
          align: 'center',
          key: 'content'
        },
        {
          title: '操作人员',
          align: 'center',
          key: 'createUserName'
        },
        {
          title: '罚款金额',
          align: 'center',
          key: 'amount',
          render: (h, param) => {
            return h('span', toMoney(param.row.amount))
          }
        }
      ],
      feeInfo: {},
      feeTypeBillList: [],
      rejectReason: ''
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData (params) {
      // 给查询条件赋值
      this.getBillInfo()
    },
    getBillInfo () {
      getSupplierBill(this.detailQuery).then((res) => {
        if (res && !res.errcode) {
          this.feeInfo = res
          res.feeTypeBillList.forEach(item => {
            item.mspTaskType = item.mspTaskType < 0 ? item.feeType : item.mspTaskType
          })
          this.feeTypeBillList = res.feeTypeBillList
          this.$store.commit(
            'set_supplierReconciliation_agentBillList',
            res.feeTypeBillList
          )
          this.defaultTab = this.feeTypeBillList[0].mspTaskType.toString()

          this.reloadMaintainAmount()
        }
      })
    },
    handleSwitchTab (name) {
      this.query.pageNumber = 1
      this.defaultTab = name
      this.reloadMaintainAmount()
    },
    // 下载明细
    handleDownload () {
      const query = {
        companyId: this.detailQuery.demandCompanyId,
        publisherId: this.detailQuery.publisherId,
        startDate: this.detailQuery.startDate,
        endDate: this.detailQuery.endDate,
        supplierId: this.detailQuery.supplierCompanyId
      }
      const filename = this.detailQuery.currentUserType === 1 ? (this.feeInfo.demandCompanyName + ' & ' + this.feeInfo.supplierCompanyName)
        : (this.detailQuery.currentUserType === 2 ? this.feeInfo.supplierCompanyName : this.feeInfo.demandCompanyName) + `对账明细（${this.detailQuery.startDate}至${this.detailQuery.endDate}）.xls`
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-dw/v1/dw/verify/exportMaintainAmountDetail', query, filename)
    },
    // 刷新列表
    reloadMaintainAmount () {
      switch (parseInt(this.defaultTab)) {
        case 52:
          this.getFineCost()
          break
        default:
          this.getMaintainAmount()
          break
      }
    },
    /**
     * 获取制作列表数据
     */
    getMaintainAmount () {
      this.dataColumns = this.tableColumns
      this.query.taskType = this.defaultTab
      listMaintainAmountDetail(this.query).then((res) => {
        this.assembleData(res)
      })
    },

    /**
     * 获取罚款费用
     */
    getFineCost () {
      this.dataColumns = this.tableColumns2

      this.fineQuery.feeType = this.defaultTab
      getPenaltyPage(this.fineQuery).then((res) => {
        this.assembleData(res)
      })
    },
    /**
     * 组装列表数据
     */
    assembleData (data) {
      if (data && !data.errcode) {
        this.list = data.list
        this.total = data.totalRow
      } else {
        this.list = []
        this.total = 0
      }
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage

      this.reloadMaintainAmount()
    },
    /**
     * 确认发起对账
     */
    handleLaunch () {
      if (this.serviceType === 1) {
        this.$Modal.confirm({
          title: '操作提示',
          content: '确定要发起对账',
          onOk: () => {
            this.handleSubmit(3)
          }
        })
      } else {
        this.$Modal.confirm({
          title: '操作提示',
          content: '确认该对账无误？',
          onOk: () => {
            this.handleSubmit(4)
          }
        })
      }
    },
    /**
     * 撤回发起对账
     */
    handleRecall () {
      let noticeTitle = ''
      let placeholder = ''
      if (this.serviceType === 1) {
        noticeTitle = '填写撤回理由'
        placeholder = '撤回理由'
      } else {
        noticeTitle = '填写拒绝理由'
        placeholder = '拒绝理由'
      }
      this.$Modal.confirm({
        title: noticeTitle,
        render: (h) => {
          return h('Input', {
            props: {
              value: this.rejectReason,
              autofocus: true,
              placeholder: placeholder
            },
            on: {
              input: (val) => {
                this.rejectReason = val
              }
            }
          })
        },
        onOk: () => {
          this.handleSubmit(2)
        }
      })
    },
    handleSubmit (status) {
      const postData = {
        billId: this.feeInfo.id,
        status: status,
        mark: this.rejectReason
      }
      updateBillStatus(postData).then((res) => {
        if (res && res.errcode === 0) {
          this.$Notice.success({ desc: '操作成功' })
          this.rejectReason = ''
          this.getBillInfo()
        } else {
          this.$Notice.error({ title: '操作过程出错', desc: res.errmsg })
        }
      })
    },
    htmlToMoney (obj) {
      return toMoney(obj)
    }
  }
}
</script>
