import request from '@/utils/requestV2'
const qs = require('qs')

/**
 * 合同的执行情况列表(不计算冲抵的条目)
 * @param {Object} data
 */
export function listPublisherContractExecuteExcludeOffset (data) {
  return request({
    url: '/ooh-dw/v1/dw/verify/listPublisherContractExecuteExcludeOffset',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 合同的时间段内的执行额列表
 * @param {Object} data
 */
export function listContractExecuteDetail (data) {
  return request({
    url: '/ooh-dw/v1/dw/verify/listContractExecuteDetail',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 合同的执行情况列表(只计算冲抵的条目)
 * @param {Object} data
 */
export function listPublisherOffsetContractExecute (data) {
  return request({
    url: '/ooh-dw/v1/dw/verify/listPublisherOffsetContractExecute',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取成本额明细
 * @param {Object} data
 */
export function listMaintainAmountDetail (data) {
  return request({
    url: '/ooh-dw/v1/dw/verify/listMaintainAmountDetail',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 合同的执行情况列
 * @param {Object} data
 */
export function listContractExecuteInfo (data) {
  return request({
    url: '/ooh-dw/v1/dw/verify/listContractExecuteInfo',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 合同的执行额统计
 * @param {Object} data
 */
export function listContractExecuteAmount (data) {
  return request({
    url: '/ooh-dw/v1/dw/verify/listContractExecuteAmount',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 合同的执行额列表的合同总额
 * @param {Object} data
 */
export function countContractAmount (data) {
  return request({
    url: '/ooh-dw/v1/dw/verify/countContractAmount',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 查询执行额明细
 * @param {Object} data
 */
export function listExecuteDetails (data) {
  return request({
    url: '/ooh-dw/v1/dw/verify/listExecuteDetails',
    method: 'post',
    data: qs.stringify(data)
  })
}
